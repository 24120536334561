@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Clicker+Script&display=swap');

body {
  font-family: "DM Sans", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* common modal for meet your designer */

.meetdesignerimage {
  display: flex;
  justify-content: center;
  align-items: center;

}


.bookimage {
  width: 100%;
}

.close-button-modal {
  float: right;
  background: none;
  color: #080B15;
  border: none;
  font-size: 20px;
}

.meetheading {
  color: #080B15;

  font-size: 40px;
  font-style: normal;
  font-weight: 700;

}

.designerform {
  color: #080B15;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

}


.whatsapp {
  position: fixed;
  background: url('./Image/wp.png') no-repeat;
  height: 50px;
  width: 50px; border: none; background-size: cover;
  right: 0px;
  top: 200px;
  visibility: visible !important;
  z-index: 999999;
}

.btnwrap button{ visibility: hidden;
  border: none; }

.form-control:focus {
  border-color: #017E2C
}

.booksessionbutton {
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #173E24;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.consultationbutton {
  padding-top: 30px;
}

.meetdesignerinput {
  padding: 5px;
}

.designerform #formMobileNo .react-phone-input-2:focus-visible {
  outline: none !important;
  /* Remove the focus outline */
}




/* end of common modal for meet your designer */





/* section 1 navbar header */


.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 23.7px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(14px);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
}

.logo {
  max-width: 142px;
  max-height: 104px;
  flex-shrink: 0;
}

.navbar-nav {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  max-width: 750px;
  max-height: 50px;
}

.nav-item {
  display: flex;
  max-height: 50px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 19px;
}

.nav-link {
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}


.dropdown-menu-row {
  font-size: 17px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}


#navbar-drop {
  max-height: 50px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  color: rgba(15, 21, 36, 0.80);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.dropdown-item:hover {
  background-color: rgba(23, 62, 36, 0.12);
  color: #017E2C;
}

.nav-link:hover {
  border-bottom: 2px solid #173E24;
  opacity: 0.8;
  color: #017E2C;
  padding-bottom: 2px;
}


#basic-nav-dropdown {
  border-bottom: none;
  padding-bottom: 2px;
}


.search-form {
  margin: 0 20px;
  display: flex;
  max-height: 50px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.60);
}

.search-icon {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
}

.search-form .form-control {
  border: none;
  background: none;
}

.form-control:focus {
  box-shadow: none;
}


.mic-icon {
  width: 24px;
  height: 24px;
  stroke-width: 1px;
}

.mainsec{ text-align: center;}
.mbanner{ display: none;}

.contactbtn {
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  display: flex;
  max-height: 50px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 0.8;
  background-color: #017E2C !important;
  border: none;
}


.contactbtn:hover {
  background-color: #173E24 !important;
}


/* end of section 1 navbar header */

.contact-img{ background: url('./images/userform_home.png') no-repeat; width: 561px; height: 558px; }

.cityicon .img-fluid{ max-width: 50%; }

/* section 2  carousel*/


.carouselHeader {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 664px;
  height: 314px;
}


.title {
  max-width: 664px;
  color: #080B15;
  text-align: center;
  font-family: "Philosopher", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
}

.bannersec {
  position: relative;
}

.info {
  max-width: 638px;
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.startbtn {
  height: 60px;
  width: 187px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  gap: 10px;
  border-radius: 8px;
  background-color: #173E24;
  text-align: center;
  font-family: "DM Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.btn:hover {
  background-color: #017E2C;
  color: #FFFFFF;
}


.carousel-inner .item {
  height: 600px;
}

/* End of section 2 carousel*/



/* section 3 our trending designs */


.container2 {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background: #FFF;
}


.title2 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.info2 {
  color: #080B15;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.8;
}

.column-img img {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.column-img {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.column-img:hover {
  transform: translateY(0px);

}

.overlay {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #173E24;
  opacity: 0;
  transition: bottom 0.3s ease, opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-img:hover .overlay {
  bottom: 0;
  opacity: 1;
}



.column-img:hover .textheading {
  transform: translateY(0);
}

.column-img:hover .textpara {
  transform: translateY(0);
}

.textheading {
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}

.textpara {
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0 20px;
}

.bookconbtn {
  margin-top: 50px;
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  background-color: #173E24;
}

.bookconbtn:hover {
  background-color: #017E2C;
}

/* end of section 2 our trending designs*/



/* section 4  just design*/

.container-fluid-4 {
  background-color: #F9F8F4;
}

.section4 {
  padding: 70px 0px;
  gap: 100px;
}

.header4 {
  gap: 25px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 60px;
}

.header4 h1 {
  color: #080b15;
  font-size: 40px;
  font-weight: 700;
}

.header4 p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #080b15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.aboutbtn4 {
  display: flex;
  max-width: 196px;
  max-height: 60px;
  padding: 10px 30px;
  font-size: 19px;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #173e24;
  text-decoration: none;
}

.aboutbtn4:hover {
  background-color: #017E2C;
  color: #FFFFFF;
}

.rectangle {
  gap: 0px;
  padding: 0px 5px;
}

.rec1 img {
  position: static;
  max-width: 230px;
  max-height: 500px;
  top: 180px;
  left: 878px;
  gap: 0px;
  border-radius: 25px;
  margin-top: 45px;
}

.rec2 img {
  position: static;
  max-width: 230px;
  max-height: 500px;
  top: 90px;
  left: 1134px;
  gap: 0px;
  border-radius: 25px;
}

.rec3 img {
  position: static;
  max-width: 230px;
  max-height: 500px;
  top: 180px;
  left: 1390px;
  gap: 0px;
  border-radius: 25px;
  margin-top: 45px;
}


/* end of section 4 just design*/



/* section 5 warranty */

.section4a {
  padding-bottom: 90px;
  background-color: #F9F8F4;
}

.core3 {
  padding: 30px 10px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card3 img {
  width: 80px;
  height: 80px;
}


.cardsize3 {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 126, 44, 0.12);
  border-radius: 100px;
}

.image3 {
  width: 80px;
  height: 80px;
}


.core-subtitle3 h5 {
  color: #080B15;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}


.core-info3 p {
  color: #080B15;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.bookdesignsession {
  max-width: 325px;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #173E24;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.bookdesignsession:hover {
  background-color: #017E2C;
  color: #FFFFFF;
}

/* end of section 5 warranty */






/* section 6 Explore Our Design Services */

.cont-6 {
  background: #FFF;
  padding: 90px 0px;
}

.title-6 {
  color: #080B15;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 10px;
}

.info-6 {
  color: #080B15;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 50px;
}

.designName {
  color: #080B15;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  padding: 10px 10px 10px 74px;
}

.viewall {
  display: inline;
  color: rgba(15, 21, 36, 0.80);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  border: none;
  background-color: #FFF;
}

h3 button {
  float: right;
}

.design-thumb {
  border-radius: 14px;
  border: rgba(0, 0, 0, 1);
  background-color: #FFF;
  padding: 10px;
  margin-bottom: 50px;
  min-height: 440px;
  
}

.design-thumb1 {
  border-radius: 14px;
  border: rgba(0, 0, 0, 1);
  background-color: #FFF;
  padding: 10px;
  margin-bottom: 50px;
  min-height: 440px;
}

.designTitle {
  color: #080B15;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.designDescription {
  color: #080B15;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
}

.carouselbutton {
  min-height: 50px;
  padding: 10px 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.buttonspan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookBtn {
  display: flex;
  height: 40px;
  padding: 9px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: #173E24;
  max-width: 155px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.bookBtn:hover {
  background-color: #017E2C;
  color: #FFFFFF;
}

.verified {
  width: 16px;
  height: 16px;
  color: #2F8FFF;
}

.verify {
  color: #090A13;
  float: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.rec-pagination {
  display: none !important;
}

.rec-swipable {
  flex-direction: row-reverse !important;
}



/* end of section 6 Explore Our Design Services */



/* section 7 end-to-end interior solutions */

.interiorSolutions {
  background-color: #F9F8F4;
}

.interiorSol {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  max-width: 1320px;
}

.heading7 {
  color: #080B15;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}


.marquee-wrapper {
  overflow: hidden;
  white-space: nowrap;
  width: 90%;

}

.marquee-wrapper .marqueecont {
  display: inline-block;
  animation: marqueeMove 20s linear infinite;
}

@keyframes marqueeMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marqueecont {
  margin: 0 80px;
}

.marqueeimg {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.marquesubt {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
}

.info8 {
  padding-bottom: 50px;
}

/* end of section 7 end-to-end interior solutions */



/* start of section 8 estimate home */
.container8 {
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background-color: #FFF;
}

.heading8 {
  color: #080B15;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.para8 {
  color: #080B15;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  opacity: 0.8;
}

.core1 {
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  align-items: center;
  margin: 0 25px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: linear-gradient(180deg, #FFF 0%, #F2FFF7 100%);
}

.cardsize1 {
  width: 100px;
  height: 100px;
}

.core-subtitle1 {
  color: #080B15;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  margin-top: 30px;
}

.core-info1 {
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 30px;
}

.calculatenow {
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #173E24;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.calculatenow:hover {
  background-color: #017E2C;
}

.offerimage {
  max-width: 500px;
  padding: 10px;
}

.estimateheading {
  color: #000;
  text-align: left;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 0px 10px 0;
}


.estimatepara1 {
  color: #000;
  text-align: left;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}

.homeType {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.continuebtn {
  display: flex;
  margin-top: 10px;
  height: 60px;
  padding: 10px 90px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: #173E24;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  float: right;
}

.continuebtn:hover {
  background-color: #017e2c;
  cursor: pointer;
}

.form-check-reverse {
  text-align: left;
  height: 50px;
  padding: 8px 17px;
  border-radius: 8px;
  border: 1px solid rgba(36, 36, 53, 0.12);
  color: #242435;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin-bottom: 10px;
}

.form-check-input:checked {
  background-color: #173E24;
  border-color: #173E24;
}


.form-check-reverse .form-check-input {
  margin-right: 0
}

.form-check-reverse:focus,
.form-check-input:focus {
  box-shadow: none;
}

.dropdown-item:active {
  background: #017E2C !important;
  color: #fff;
}

.backbutton {
  padding: 10px 0px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  background: none;
  color: rgba(15, 21, 36, 0.80);
  text-align: center;
  font-size: 19px;
  position: relative;
  bottom: -14px;
  font-weight: 700;
}

.backbutton:hover {
  background: none;
  color: #173E24;
}

.btn-primary {
  --bs-btn-border-color: none !important;
  --bs-btn-active-bg: ##173E24;
}

.modalbuttons {
  justify-content: space-between;
}

.estimatebutton {
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: #173E24;
  float: right;
}

.modal {
  --bs-modal-header-border-color: none;
  --bs-modal-header-padding: 0.5rem 0.5rem;
}

.row {
  --bs-gutter-x: 0.5rem;
}



/* end of section 8  estimate home*/


/* section 9 core value */

.section5 {
  padding: 50px 0;
}


.core-title h2 {
  color: var(--Dark, #080B15);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 50px;
}

.core {
  padding: 30px 10px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardsize img {
  width: 80px;
  height: 80px;
}

.cardsize img {
  background-color: #FFF;
  border-radius: 0;
}

.core-subtitle h5 {
  color: #080B15;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}


.core-info p {
  font-weight: 400;
  text-align: center;
  color: #080B15;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

}

/* end of section 9 core value */




/* section 10 counter  */

.card-content {
  padding: 60px 0px;
}

.value {
  font-size: 66px;
  font-weight: 700;
  text-align: center;
  font-family: "Philosopher", sans-serif;
}

.count-text {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}

.card-img {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.counterImage {
  width: 70px;
  height: 70px;
}





/* end of section 10 counter*/












/* section 11 testimonial */

.cont-bg {
  background-image: url("./images/Feedback.jpg");
}

.container-7 {
  padding: 90px 0px;
}

.title-7 {
  color: #0F1524;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 50px;
}

.card-body {
  display: inline-flex;
  padding: 30px;
  align-items: center;
  border-radius: 30px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.12);
}

.testimonial {
  max-width: 439px;
  max-height: 470px;
  border-radius: 15px;
}

.testimonial-info {
  padding-left: 164px;
}

.quoteleft {
  display: flex;
  width: 38px;
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  color: #080B15;
  font-family: "Philosopher", sans-serif;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.test-info {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  text-align: left;
  padding: 10px 0;
}

.test-name {
  color: #080B15;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  padding-top: 10px;
  text-align: left;
  margin: 0;
}

.test-name1 {
  color: #080B15;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  margin: 0;
}

#testimonialCarousel {
  position: relative;
}

.carousel-control-prev1 {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);
  width: 40px;
  height: 40px;
  background-color: rgba(198, 128, 72, 0.2);
  border-radius: 50%;
  color: #fff;
  border: none;
}


.carousel-control-next1 {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);
  width: 40px;
  height: 40px;
  background-color: rgba(198, 128, 72, 0.2);
  border-radius: 50%;
  color: #fff;
  border: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
}

.carousel-control-prev1 {
  left: 1150px;
}

.carousel-control-next1 {
  right: 70px;
}




/* end of section11 testimonial */


/* faq */

.FQA {
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
}

.faq {
  margin-bottom: 67px;
}

.faqheader {
  color: #080B15;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 600px;
}



.searchfaq {
  margin: 0 20px;
  max-width: 400px;
  display: flex;
  max-height: 50px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.60);
}


.searchfaq .form-control {
  border: none;
  background: none;
}

.form-control:focus {
  box-shadow: none;
}

.accordion-box {
  padding: 10px 40px;
  border-radius: 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 30px;
}

.accordion-header button {
  color: #080B15;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;

}

.accordion-button {
  background: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  color: #080B15 !important;
}

.accordion-body {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  text-align: left;
}

/* end of faq */






/* section 8  create form*/

.contactusform {
  margin-top: 100px;
}

.contact-form {
  background-color: #F9F8F4;
  padding: 90px 0px;
}

.create-form {
  max-height: 840px;
  gap: 50px;
}

.header8 {
  color: #0F1524;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 50px;
}

.contact-info {
  max-width: 1087px;
  max-height: 558px;
  display: flex;
  gap: 100px;
  align-items: flex-end;
}

.contact-img img {
  max-width: 560px;
  max-height: 558px;
  gap: 0px;
  border-radius: 16px;
  background-color: linear-gradient(180deg, #D9D9D9 0%, #C5C5C5 47%, #CDCDCD 100%);
}


.form-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding-left: 120px;
}

.form-content {
  text-align: left;
}

.form-content input[type="text"],
.form-content input[type="email"],
.form-content input[type="tel"] {
  display: flex;
  width: 450px;
  height: 50px;
  padding: 10px 15px;
  text-align: left;
  margin-bottom: 15px;
}


.form-content textarea {
  display: flex;
  width: 450px;
  height: 80px;
  padding: 10px 15px;
  text-align: left;
  gap: 10px;
  margin-bottom: 15px;
}

#formName-label,
#formEmail-label,
#formMobile-label,
#formMessage-label {
  position: absolute;
  left: -1px;
  top: -29px;
  color: #0F1524;
  font-size: 16px;
  font-weight: 500;
  line-height: 145%;
}

.submitbutton {
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  max-height: 50px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  background-color: #173E24;
}

.submitbutton:hover {
  background-color: #017E2C;
}


/* end of section 8 */



/* section 9 */

/* footer css */



.footer-css {
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  align-items: center;
  background-color: #173E24;
}

.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.company-info {
  display: inline-block;
  text-align: left;
  padding-right: 80px;
}

.companylogo {
  max-width: 142.361px;
  max-height: 104px;
  float: left;
}

.company-info p {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  clear: both;
  padding-left: 10px;
  align-items: center;
  justify-self: center;
}


.company-info h6 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  text-align: left;
  padding-left: 10px;
  justify-content: center;
}

.nav-link1 {
  display: flex;
  padding: 10px;
  text-align: left;
  color: white;
}

.company-info ul li :Link {
  text-decoration: none;
  color: #FFF;
}

.social-icons {
  padding-right: 16px;
  padding-top: 20px;
  display: inline-flex;
}

.copyright {
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.8;
  padding-top: 50px;
}

.social-icons :Link i {
  color: white;
  font-size: 24px;
  margin-right: 10px;
  text-decoration: none;
}

i {
  color: white;
  text-decoration: none;
}

.footer-locations {
  padding-top: 10px;
}

.locationhead {
  color: #FFF;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  padding: 0px 0 0 20px;
}

.locationname {
  float: left;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}


.text-white ul {
  margin: 0px;
  padding: 0px 0 0 26px;
}


/* end of section 9 */















/*  start Gallery page css */

.gallerysec {
  padding-top: 150px;
}

.gallery-heading {
  padding: 10px 0 50px 0;
}

.gallery-heading h2 {
  color: #000;
  text-align: center;
  font-family: Philosopher;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
}

.gallery-heading p {
  padding-top: 16px;
  color: #080B15;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.carousel-indicators {
  z-index: 0;
}

.galleryCard img {
  height: 250px;
  width: 100%;
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.imageContainer:hover .imageOverlay {
  opacity: 1;
  border-radius: 8px;
}

.imageOverlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  justify-content: center;
  align-items: center;
}

.overlayText {
  font-size: 19px;
  color: #FFF;
  text-align: center;
  padding: 10px;

}

.imageContainer img {
  border-radius: 8px;
}

.modelbtn {
  color: white;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.gallerymodalimage {
  border-radius: 15px;
}

.gallerymodaltext {
  padding: 15px;
}

.gallerymodaltext h4 {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 8px;
}

.gallerymodaltext p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.container {
  --bs-gutter-x: 0.5rem;
}

.btn-css {

  position: absolute;
  top: 15px;
  right: 10px;
  z-Index: 1050
}

.detailbtn button {
  position: absolute;
  bottom: 15px;
  display: flex;
  min-height: 50px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  gap: 10px;
  color: white;
  border-radius: 8px;
  background-color: #173E24;
  border: none;
}

.detailbtn button:hover {
  background-color: #017E2C;
}

/* end of gallery page */



/* service page */

.design-details {
  padding-top: 170px;
}

.carouselslider {
  padding-bottom: 50px;
}

.imageblog {
  border-radius: 8px;
  height: 230px;
}

.carouselbutton {
  height: 50px;
  padding: 10px 21px;
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.carouselbutton1 {
  height: 50px;
  padding: 10px 21px;
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}



.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: black;
}


.btn-primary {
  --bs-btn-bg: none;
  /* color: #080B15; */
}

 .view{
  --bs-btn-bg: #014e1c;
}

.btn.active {
  color: #FFF;
  /* background-color: #017E2C; */
}

.read-more {
  cursor: pointer;
  font-weight: 600;
}

.read-less {
  cursor: pointer;
  font-weight: 600;
}

.headingservice {
  color: #080B15;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 15px;
}
.dash{ font-size: 30px; }


.serviceimagemain {
  padding-bottom: 50px;
  width: 100%;
}

.serviceimagemain1 {
width: 100%;
  padding-bottom: 50px;

}

.servicepage2 {
  height: 470px;
}

/* .servicepage {
  flex-direction: row-reverse;
} */

.position-relative {
  position: relative;
  /* Ensure relative positioning for the parent container */
}

.new-tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: lightcoral;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
}


/* end of service page */


/* about page */

.aboutsec3 {
  background: url("./images/aboutpagebackground.jpg") no-repeat;
  width: 100%;
  background-size: cover;
}

.container-fluid.about {
  position: relative;
}

.aboutpage img {
  max-width: 100%;
  height: auto;
  position: relative;
}

.aboutheader {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  max-width: 600px;
  font-family: "Philosopher", sans-serif;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aboutsec1 {
  padding: 90px 0px;
}

.aboutheader1 {
  color: #080B15;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aboutinfo1 {
  color: #080B15;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  padding-top: 30px;
  opacity: 0.8;
}

.contactbtn1 {
  display: inline-block;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  background-color: #173E24;
  margin-top: 30px;
}

.contactbtn1:hover {
  background-color: #017E2C;
}

.aboutsec2 {
  display: flex;
  padding: 70px 0;
  flex-direction: column;
  align-items: flex-start;
  background-color: #F9F8F4;

}

.aboutsec22 {
  display: flex;
  padding: 70px 0;
  flex-direction: column;
  align-items: flex-start;
  background-color: #F9F8F4;

}

.aboutheader2 {
  color: #080B15;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.aboutinfo2 {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.8;
  padding-top: 10px;
  text-align: left;
}

.contactbtn2 {
  display: flex;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  background-color: #173E24;
  margin-top: 30px;
}

.contactbtn2:hover {
  background-color: #017E2C;
}

.aboutimg2 {
  border-radius: 8px;
}

.aboutsec3 {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  align-items: center;
  background-color: #F9F8F4;
}

.aboutpage3 h2 {
  color: #ffffff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.aboutpage3 p {
  padding-top: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.aboutpage3 {
  padding-bottom: 50px;
}

.about-card {
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  padding: 30px 15px;
  margin: 0px 25px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 37px 0px rgba(80, 61, 17, 0.06);
}

.aboutimg3 {
height: 250px;
  padding-bottom: 20px;
}

.about-card1 h5 {
  color: #080B15;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
}

.about-card1 h6 {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  padding: 6px 0;
}

.about-card1 p {
  color: #080B15;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  opacity: 0.8;
}

.aboutsec4 {
  display: flex;
  padding: 90px 0px;
  flex-direction: column;
  gap: 50px;
}

.aboutheader4 {
  color: #080B15;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.cities {
  color: #814D19;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin: 30px 0 30px 0;
}

/* end of about page */





/* start luxuryDEsign css */

.luxcarousalheading {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 26.6px #291B09;
  font-family: "Cinzel Decorative", serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 950px;
  left: 50%;
}

.seclux .carousel-caption {
  position: absolute;
  top: 44%;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  z-index: 100;
  margin-top: 226px;
  color: #fff;
  text-align: center;
}

.seclux {
  position: relative;
}

.getbtn {
  font-size: 23px;
  text-align: center;
  padding: 10px 40px;
  gap: 10px;
  border: white;
  border-radius: 8px;
  background: linear-gradient(90deg, #AB8135 0%, #BE9956 100%);
  color: white;
}

.test {
  position: relative;
}

.sectionlux2 {
  padding: 90px 0;
  background-color: #FFFFFF;
}

.luxsec2heading {
  color: #080B15;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.luxe {
  color: #080B15;
  font-family: "Clicker Script", cursive;
  font-size: 90px;
  font-style: normal;
  font-weight: 400;
}

.core4 {
  padding: 50px 10px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card4 img {
  width: 80px;
  height: 80px;
}

.cardsize4 {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.core-subtitle4 h6 {
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #080B15;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}

.bookdesignsession1 {
  height: 70px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--gold-gradient, linear-gradient(90deg, #AB8135 0%, #BE9956 100%));
  color: #FFF;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
  border: none;
}


.luxsec {
  background: #E7DEC9;
  padding: 90px 0;
}


.luxuryname:hover {
  background: linear-gradient(90deg, #AB8135 0%, #BE9956 100%);
}

.luxserviceheading {
  color: #080B15;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.luxuryname {
  color: #000;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  padding: 16px 0;
  background: rgba(255, 255, 255, 0.60);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.imageContainer1 img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.PhoneInput input {
  border: none;
}

.galleryslider button.active {
  color: #000;
}



.zoom-effect-container {
 
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}


.zoom-effect-container:hover .imageContainer1 img {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
  transition: transform 1s;
}

/* end luxuryDesign css */



@media screen and (max-width:600px) {


  .contact-box .contact-img{ display: none; }
  .mbanner img{ width: 100% ; display: block !important;}
  .mbanner{  display: block;} 


  .navbar {
    flex-shrink: inherit;
    width: 100%;
  }

  .fixed-top {
    position: inherit;
  }

  .navbar-nav {
    max-height: inherit;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 100;
    max-width: 513px;
    max-height: 150px;
    overflow-y: auto;
  }
.serviceimagemain{ display: none;}
  .dropdown-menu-row {
    grid-template-columns: repeat(2, 1fr);
  }

  #carouselExample{ display: none;}

  .carousel-inner .item {
    height: auto;
  }

  .carouselHeader {
    top: 210px;
    display: none;
  }

  .title {
    font-size: 29px;
  }

  .info,
  .email-contact {
    display: none;
  }

  .cont-6 .ms-5 {
    margin-left: 0px !important;
  }

  .buttonspan {
    display: block;
  }

  .verify {
    display: none;
  }

  .contact-img img {
    display: none;
  }

  .form-info {
    padding-left: 0px;
  }

  .form-content input[type="text"],
  .form-content input[type="email"],
  .form-content input[type="tel"] {
    width: 90%;
  }

  .form-content textarea {
    height: auto !important;
    width: 90%;
  }

  .contact-info {
    display: block;
  }

  .rectangle {
    display: none;
  }

  .bookimage {
    display: none;
  }

  .meetheading {
    font-size: 25px;
  }

  .form-content {
    text-align: left;
    width: 100%;
    margin: 0 20px;
  }

  .design-details {
    padding-top: 30px;
  }

  .title2 {
    font-size: 34px;
  }

  .cardBody img {
    margin-top: 10px;
  }

  .column-img img {
    margin-top: 10px;
    width: 100%;
  }

  .design-thumb1{ min-height: auto; }
  .cont-6{ padding: 30px 0;}
  .container8{ padding: 30px 0;}
  .faq{ margin-bottom: 0px; }
  .FQA{ padding: 30px 0;}
  .footer-css{ padding-top: 0px;}

  .container2 {
    gap: 0px;
    padding: 30px 0px ;
  }

  .section4 {
    padding: 0px 10px 30px 10px;
  }

  .header4 h1 {
    font-size: 30px;
  }

  .section4a {
    padding-bottom: 10px;
  }

  .title-6 {
    font-size: 34px;
  }

  .heading8 {
    font-size: 34px;
  }

  .core1 {
    margin: 10px;
  }

  .offerimage {
    display: none;
  }

  .estimateheading {
    font-size: 30px;
  }

  .core-title h2 {
    font-size: 34px;
  }

  .title-7 {
    font-size: 34px;
  }

  .testimonial {
    display: none;
  }

  .testimonial-info {
    padding-left: 0px;
  }

  .carousel-control-prev1 {
    left: 240px;
  }

  .carousel-control-next1 {
    right: 0px;
  }

  .faqheader {
    font-size: 34px;
    padding-left: 10px;
  }

  .accordion-header button {
    font-size: 16px;
  }

  .accordion-body {
    font-size: 16px;
  }

  .accordion-box {
    padding: 10px;
    margin: 10px;
  }

  .contact-form {
    padding: 40px 0;
  }

  .header8 {
    font-size: 34px;
  }

  .aboutheader {
    font-size: 20px;
    top: 63px;
  }

  .aboutheader1 {
    font-size: 34px;
  }

  .aboutsec2 {
    padding: 40px 10px;
  }

  .aboutheader2 {
    font-size: 34px;
  }

  .aboutimg2 {
    width: 100%;
  }

  .aboutpage3 h2 {
    font-size: 34px;
  }

  .about-card {
    margin: 10px;
  }

  .aboutheader4 {
    font-size: 34px;
  }

  .cities p {
    font-size: 15px;
  }

  .aboutsec4 {
    padding: 40px 10px;
  }

  .contactusform {
    margin-top: 0px;
  }

  .luxcarousalheading {
    font-size: 20px;
  }

  .seclux .carousel-caption {
    margin-top: 25px;
  }

  .getbtn {
    display: none;
  }


  .sectionlux2 {
    padding: 0;
  }

  .luxsec2heading {
    font-size: 34px;
  }

  .luxe {
    font-size: 65px;
  }

  .core4 {
    padding: 15px 0px;
  }

  .bookdesignsession1 {
    padding: 10px 18px;
    font-size: 19px;
  }

  .section5 {
    padding: 0px;
  }

  .aboutsec4 {
    gap: 15px;
  }

  .gallerysec {
    padding-top: 0px;
  }

  .btn-css {
    top: 5px;
    right: 0px;
  }

  .gallery-heading h2 {
    font-size: 34px;
  }

  .company-info {
    display: inline-block;
    text-align: center;
    padding: 26px;
  }

  .companylogo {
    margin-left: 100px;
  }

  .company-info .social h6 {
    margin-left: 10px;
  }

  .social-icons {
    margin-left: 70px;
    padding: 0px;
  }

  .social h6 {
    text-align: center;
  }

  .social {
    padding: 0;
  }


  .counter-cards {
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 40px;
  }

  .card-img,
  .counter {
    flex-basis: 50%;
  }

  .aboutpageimg2 {
    width: 100%;
  }

  .overlay {
    height: 98%;
  }

  .serviceimagemain {
    width: 350px;
  }

  .imageblog {
    height: auto;
  }

  .design-thumb1 {
    height: auto;
  }

}










@media (min-width:992px) {

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    width: 798px !important;
  }
}





@media screen and (min-width:800px) and (max-width:1200px) {

  .navbar {
    flex-shrink: inherit;
    width: 100%;
  }

  .fixed-top {
    position: inherit;
  }

  .navbar-nav {
    max-height: inherit;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    z-index: 100;
    max-width: 513px;
    max-height: 150px;
    overflow-y: auto;
  }

  .carousel-inner .item {
    height: auto;
  }

  .title {
    font-size: 47px;
  }

  .carouselHeader {
    top: 210px;
  }

  .column-img img {
    width: 100%;

  }

  .column-img {
    width: 50%;
    margin-bottom: 10px;
  }

  .header4 {
    margin-top: 0px;
  }

  .rec2 img {
    display: none;
  }

  .rec3 img {
    margin-left: -60px;
  }

  .design-thumb1 {
    height: 400px;
  }

  .core1 {
    margin: 0px;
  }

  .calculatenow {
    padding: 10px 13px;
  }

  .section5 {
    padding: 0px;
  }

  .card-img {
    margin-left: 0px;
  }

  .value {
    font-size: 43px;
  }

  .count-text {
    font-size: 16px;
  }

  .offerimage {
    display: none;
  }

  .testimonial {
    max-width: 325px;
  }

  .carousel-control-prev1 {
    left: 580px;
  }

  .carousel-control-next1 {
    right: 35px;
  }

  .contact-img img {
    max-width: 350px;
    margin-top: 20px;
  }

  .form-info {
    padding-left: 30px; /* Adjust the left and right padding as needed */
  }

  .form-content input[type="text"],
  .form-content input[type="email"],
  .form-content input[type="tel"] {
    width: 350px;
    height: 35px;
  }

  .form-content textarea {
    width: 350px;
    height: 50px;
  }

  .serviceimagemain {
    width: 100%;
  }

  .design-details {
    padding-top: 60px;
  }

  .gallerysec {
    padding-top: 10px;
  }

  .gallerymodaltext p {
    height: 180px;
  }

  .imageblog {
    height: 150px;
  }

  .company-info {
    padding-right: 0px;
  }

  .bookBtn {
    padding: 10px;
  }

  .galleryCard img {
    height: auto;
  }

  .luxcarousalheading {
    font-size: 31px;
  }

  .seclux .carousel-caption {
    margin-top: 110px;
  }

  .getbtn {
    font-size: 20px;
  }

  .luxuryCard {
    width: 50%;
  }

  .imageContainer1 img {
    width: 100%;
  }

  .contactusform {
    margin-top: 0px;
  }

  .aboutheader {
    font-size: 44px;
    top: 160px;
  }

  .aboutpageimg2 {
    width: 300px;
    padding-top: 60px;
  }

  .cities {
    font-size: 22px;
  }
}

.form-control{
  display: flex !important;
}


.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px; 
  overflow-y: auto;
  transition: opacity 0.2s ease, transform 0.2s ease;
  transform: translateY(0);
  opacity: 1;
}

.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestions-list li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
}

.suggestions-list li:hover {
  background-color: #f8f9fa;
  transform: scale(1.02);
}

.suggestions-list li a {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.no-suggestions {
  padding: 10px 15px;
  color: #6c757d;
  text-align: center;
}

.search-form .form-control {
  padding-right: 30px; 
}

.search-form .search-icon, .search-form .mic-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  justify-content: space-between;
}

.search-form .search-icon {
  right: 8px;
}

.search-form .mic-icon {
  right: 10px;
}


.phone-icon {
  position: fixed;
  top: 290px;
  right: 0px;
  width: 50px;
  height: 50px;
  background-color: #173E24; 
  color: white;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 24px;
  z-index: 1000;
  cursor: pointer;
}

.phone-icon:hover {
  background-color: #128c7e; /* Darker green */
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
}

.loading-logo {
  width: 150px;
  margin-bottom: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #3b82f6;
  animation: spin 1s linear infinite;
}

.loading-percentage {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.privacy-container {
  margin: 120px auto;
  text-align: left;
  max-width: 800px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.8;
}

.privacy-title {
  text-align: center;
  color: #a3672a;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  border-bottom: 2px solid #173E24;;
  padding-bottom: 10px;
}

.privacy-date {
  text-align: center;
  font-style: italic;
  color: #555;
  margin-bottom: 30px;
}

.privacy-intro {
  margin-bottom: 30px;
}

.privacy-section {
  margin-bottom: 30px;
}

.privacy-heading {
  font-size: 22px;
  color: #a3672a;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.privacy-text {
  margin-bottom: 20px;
}

.privacy-list {
  padding-left: 20px;
  list-style-type: disc;
  margin-bottom: 20px;
}

.privacy-list-item {
  margin-bottom: 10px;
}

.©{
  max-height: 100px;
}
